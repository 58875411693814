<template>
    <div class="fill-height px-5 px-sm-10 py-5 py-sm-5" v-if="AppStore.started_app" id="timeline-page">
        <v-container class="fill-height" fluid>
            <v-row no-gutters style="flex-wrap: nowrap;" v-if="isLandscape">
                <!--                <v-col cols="2" class="flex-grow-0 flex-shrink-0 text-center">-->
                <!--                    <v-card v-for="(stat, i) in AppStore.data.configuration.timeline_sources_values" :key="'stat'+i"-->
                <!--                            class="text-center pa-5 mb-5" width="175px" rounded="xl">-->
                <!--                        <div class="stats text-uppercase font-weight-bold">{{-->
                <!--								AppStore.data.unit.statistics[AppStore.data.configuration.timeline_stats_sources][stat.key]-->
                <!--                            }}<span v-if="stat.key.slice(0, 5) === 'rate_'">/5</span></div>-->
                <!--                        <span v-html="AppStore.data.configuration[AppStore.locale][stat.key]"></span>-->
                <!--                    </v-card>-->
                <!--                </v-col>-->
                <v-col cols="1" style="min-width: 100px; max-width: 100%;" class="flex-grow-1 flex-shrink-0">
                    <v-col cols="12" class="px-7" v-if="AppStore.data.configuration.display_thanks_messages">
                        <v-card class="text-subtitle-1 text-center pa-4" flat rounded="xl" id="timeline-thanks-message"
                                width="100%">
                            <v-col cols="12" class="text-h5 my-auto">
                                <span v-if="Helpers.isNegativeWithComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_with_comment"></span>
                                <span v-if="Helpers.isNegativeWithoutComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_without_comment"></span>
                                <span v-if="Helpers.isPositiveWithComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_with_comment"></span>
                                <span v-if="Helpers.isPositiveWithoutComment()"
                                      v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_without_comment"></span>
                            </v-col>
                            <v-col cols="12 pa-0">
                                <v-btn @click="$router.push({name: 'mb-2-home', query: AppStore.query_params});"
                                       v-if="!AppStore.data.general.post_single_rating"
                                       @keypress.enter="navigate" role="link" rounded
                                       outlined elevation="0"
                                       :large="AppStore.data.general.button_size === 'large'"
                                       :x-large="AppStore.data.general.button_size === 'x-large'"
                                       :x-small="AppStore.data.general.button_size === 'x-small'"
                                       :small="AppStore.data.general.button_size === 'small'"
                                       :color="AppStore.data.general.primary_color.hexa"
                                       :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                                       class="ma-4">
                                    <v-icon left dark>
                                        mdi-arrow-left
                                    </v-icon>
                                    {{ AppStore.data.configuration[AppStore.locale].home_btn }}
                                </v-btn>
                            </v-col>
                        </v-card>
                    </v-col>
                    <!--                    <v-col cols="12" class="px-6 text-h2 font-weight-bold" id="timeline-title"-->
                    <!--                           v-html="AppStore.data.configuration[AppStore.locale].timeline_title">-->
                    <!--                    </v-col>-->
                    <!--                    <v-container class="px-10 py-8" fluid>-->
                    <!--                        <v-row v-for="(rating, i) in AppStore.data.unit.ratings" :key="i"-->
                    <!--                               class="mb-5 pa-3 timeline-row">-->
                    <!--                            <v-col cols="12" class="pa-0 ">-->
                    <!--                                <v-rating-->
                    <!--                                        half-increments-->
                    <!--                                        dense-->
                    <!--                                        readonly-->
                    <!--                                        size="25"-->
                    <!--                                        color="orange"-->
                    <!--                                        background-color="grey"-->
                    <!--                                        v-model="rating.rate"-->
                    <!--                                        icon-label=""-->
                    <!--                                        class="d-inline-block mr-2 float-left"></v-rating>-->

                    <!--                                <div class="font-weight-bold ml-2">{{ rating.rate + '/5' }}</div>-->
                    <!--                            </v-col>-->
                    <!--                            <v-col cols="12" class="pa-0 ml-1 pt-2">-->
                    <!--                                              <span class="mr-auto caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1"-->
                    <!--                                                    v-if="rating.source === 'google' || rating.source === 'google_places'">-->
                    <!--                                              {{ $t('views.timeline.rating_from') }} {{ rating.consumer.identifier }}-->
                    <!--                                               <span v-if="rating.consumer.influence" class="caption">-->
                    <!--                                               {{ $t('views.home.local_guide') }} {{ rating.consumer.influence / 10 }}-->
                    <!--                                               </span> {{ $t('views.timeline.of') }}     <span class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                    <!--                                                                    style="margin-top: 2px">-->
                    <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                    <!--                                        </span>-->
                    <!--                                          </span>-->

                    <!--                                <span class="caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1"-->
                    <!--                                      v-if="rating.source === 'civiliz' && rating.consumer && rating.consumer.signature !== 'anonymous' && rating.consumer.signature !== 'anonyme'">-->
                    <!--                                            {{ $t('views.timeline.rating_from') }} {{ rating.consumer.signature }} {{ $t('views.timeline.of') }}     <span-->
                    <!--                                        class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                    <!--                                        style="margin-top: 2px">-->
                    <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                    <!--                                        </span>-->
                    <!--                                          </span>-->
                    <!--                                <span v-else class="text-body-1 grey&#45;&#45;text text&#45;&#45;darken-1">-->
                    <!--                                         {{ $t('views.timeline.rating_of') }} <span-->
                    <!--                                        class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                    <!--                                        style="margin-top: 2px">-->
                    <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                    <!--                                        </span>-->
                    <!--                                    </span>-->
                    <!--                            </v-col>-->
                    <!--                            <v-col cols="12" class="pa-0 ml-1 pt-2" v-if="rating.contents.length">-->

                    <!--                                &lt;!&ndash;                        <div class="pt-2 block-ellipsis ml-1 text-body-1" v-if="rating.contents.length">&ndash;&gt;-->
                    <!--                                {{ rating.contents[0].content }}-->
                    <!--                                &lt;!&ndash;                        </div>&ndash;&gt;-->
                    <!--                            </v-col>-->
                    <!--                            <v-col cols="12" v-if="rating.has_answers">-->
                    <!--                                <v-card v-for="(answer, i) in rating.answers" :key="i" class="answer-card pa-3 d-flex"-->
                    <!--                                        rounded="xl" flat>-->

                    <!--                                    <v-row>-->
                    <!--                                        <v-col cols="12 pb-0">-->
                    <!--                                            <div class="caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1">-->
                    <!--                                                {{ AppStore.data.configuration[AppStore.locale].timeline_answer_title }}-->
                    <!--                                            </div>-->
                    <!--                                        </v-col>-->
                    <!--                                        <v-col cols="12 d-flex">-->
                    <!--                                            <div>-->
                    <!--                                                {{ answer.content }}-->
                    <!--                                            </div>-->
                    <!--                                            <div class="ml-auto">-->
                    <!--                                                <v-img-->
                    <!--                                                        v-if="!_.isEmpty(AppStore.data.configuration[AppStore.locale].client_logo_2)"-->
                    <!--                                                        max-width="50"-->
                    <!--                                                        :src="AppStore.data.configuration[AppStore.locale].client_logo_2"-->
                    <!--                                                ></v-img>-->
                    <!--                                            </div>-->
                    <!--                                        </v-col>-->
                    <!--                                    </v-row>-->

                    <!--                                </v-card>-->
                    <!--                            </v-col>-->
                    <!--                        </v-row>-->

                    <!--                    </v-container>-->
                </v-col>
            </v-row>

            <v-row v-if="isPortrait" class="">
                <v-card elevation="0" class="text-subtitle-1 text-center pa-4 ma-0 ma-sm-5" flat rounded="xl"
                        id="timeline-thanks-message"
                        width="100%" v-if="AppStore.data.configuration.display_thanks_messages">
                    <v-col cols="12" class="text-h5 my-auto">

                        <span v-if="Helpers.isNegativeWithComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_with_comment"></span>
                        <span v-if="Helpers.isNegativeWithoutComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_negative_without_comment"></span>
                        <span v-if="Helpers.isPositiveWithComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_with_comment"></span>
                        <span v-if="Helpers.isPositiveWithoutComment()"
                              v-html="AppStore.data.configuration[AppStore.locale].profil_title_positive_without_comment"></span>
                    </v-col>
                    <v-col cols="12 pa-0">
                        <v-btn @click="$router.push({name: 'mb-2-home', query: AppStore.query_params});"
                               v-if="!AppStore.data.general.post_single_rating"
                               @keypress.enter="navigate" role="link" rounded
                               outlined elevation="0"
                               :large="AppStore.data.general.button_size === 'large'"
                               :x-large="AppStore.data.general.button_size === 'x-large'"
                               :x-small="AppStore.data.general.button_size === 'x-small'"
                               :small="AppStore.data.general.button_size === 'small'"
                               :color="AppStore.data.general.primary_color.hexa"
                               :style="Helpers.getButtonSize(AppStore.data.general.button_size)"
                               class="ma-4">
                            <v-icon left dark>
                                mdi-arrow-left
                            </v-icon>
                            {{ AppStore.data.configuration[AppStore.locale].home_btn }}
                        </v-btn>
                    </v-col>
                </v-card>

                <!--                <v-col cols="12 d-flex justify-space-between px-0" v-if="$vuetify.breakpoint.smAndUp">-->
                <!--                    <v-card v-for="(stat, i) in AppStore.data.configuration.timeline_sources_values" rounded="xl" :key="'stat' + i"-->
                <!--                            class="text-center pa-5 d-inline-block " width="175px">-->
                <!--                        <div class="stats text-uppercase font-weight-bold">{{-->
                <!--								AppStore.data.unit.statistics[AppStore.data.configuration.timeline_stats_sources][stat.key]-->
                <!--                            }}<span v-if="stat.key.slice(0, 5) === 'rate_'">/5</span></div>-->
                <!--                        <span v-html="AppStore.data.configuration[AppStore.locale][stat.key]"></span>-->
                <!--                    </v-card>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" v-if="$vuetify.breakpoint.xsOnly" v-for="(stat, i) in AppStore.data.configuration.timeline_sources_values" :key="'stat' + i">-->
                <!--                    <v-sheet rounded="xl"-->
                <!--                            class="text-center pa-5 d-inline-block">-->
                <!--                        <div class="stats text-uppercase font-weight-bold">{{-->
                <!--                                AppStore.data.unit.statistics[AppStore.data.configuration.timeline_stats_sources][stat.key]-->
                <!--                            }}<span v-if="stat.key.slice(0, 5) === 'rate_'">/5</span></div>-->
                <!--                        <span v-html="AppStore.data.configuration[AppStore.locale][stat.key]"></span>-->
                <!--                    </v-sheet>-->
                <!--                </v-col>-->
                <!--                <v-col cols="12" class="px-0 text-h2 font-weight-bold pb-0 pb-sm-5" id="timeline-title"-->
                <!--                       v-html="AppStore.data.configuration[AppStore.locale].timeline_title">-->
                <!--                </v-col>-->
                <!--                <v-container class="my-5" fluid>-->
                <!--                    <v-row v-for="(rating, i) in AppStore.data.unit.ratings" :key="i" class="mb-5 pa-3 timeline-row">-->
                <!--                        <v-col cols="12" class="pa-0 ">-->
                <!--                            <v-rating-->
                <!--                                    half-increments-->
                <!--                                    dense-->
                <!--                                    readonly-->
                <!--                                    size="25"-->
                <!--                                    color="orange"-->
                <!--                                    background-color="grey"-->
                <!--                                    v-model="rating.rate"-->
                <!--                                    icon-label=""-->
                <!--                                    class="d-inline-block mr-2 float-left"></v-rating>-->

                <!--                            <div class="font-weight-bold ml-2">{{ rating.rate + '/5' }}</div>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" class="pa-0 ml-1 pt-2">-->
                <!--                                              <span class="mr-auto caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1"-->
                <!--                                                    v-if="rating.source === 'google' || rating.source === 'google_places'">-->
                <!--                                              {{ $t('views.timeline.rating_from') }} {{ rating.consumer.identifier }}-->
                <!--                                               <span v-if="rating.consumer.influence" class="caption">-->
                <!--                                               {{ $t('views.home.local_guide') }} {{ rating.consumer.influence / 10 }}-->
                <!--                                               </span> {{ $t('views.timeline.of') }}     <span class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                <!--                                                                    style="margin-top: 2px">-->
                <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                <!--                                        </span>-->
                <!--                                          </span>-->

                <!--                            <span class="caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1"-->
                <!--                                  v-if="rating.source === 'civiliz' && rating.consumer && rating.consumer.signature !== 'anonymous' && rating.consumer.signature !== 'anonyme'">-->
                <!--                                            {{ $t('views.timeline.rating_from') }} {{ rating.consumer.signature }} {{ $t('views.timeline.of') }}     <span-->
                <!--                                    class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                <!--                                    style="margin-top: 2px">-->
                <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                <!--                                        </span>-->
                <!--                                          </span>-->
                <!--                            <span v-else class="text-body-1 grey&#45;&#45;text text&#45;&#45;darken-1">-->
                <!--                                         {{ $t('views.timeline.rating_of') }} <span-->
                <!--                                    class="d-inline-block grey&#45;&#45;text text&#45;&#45;darken-1"-->
                <!--                                    style="margin-top: 2px">-->
                <!--                                        {{ Helpers.parseDate(rating.posted_at_tz, 'DD/MM/YYYY') }}-->
                <!--                                        </span>-->
                <!--                                    </span>-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" class="pa-0 ml-1 pt-2" v-if="rating.contents.length">-->

                <!--                            &lt;!&ndash;                        <div class="pt-2 block-ellipsis ml-1 text-body-1" v-if="rating.contents.length">&ndash;&gt;-->
                <!--                            {{ rating.contents[0].content }}-->
                <!--                            &lt;!&ndash;                        </div>&ndash;&gt;-->
                <!--                        </v-col>-->
                <!--                        <v-col cols="12" v-if="rating.has_answers">-->
                <!--                            <v-card v-for="(answer, i) in rating.answers" :key="i" class="answer-card pa-3 d-flex"-->
                <!--                                    rounded="xl" flat>-->

                <!--                                <v-row>-->
                <!--                                    <v-col cols="12 pb-0">-->
                <!--                                        <div class="caption grey&#45;&#45;text text-body-1 text&#45;&#45;darken-1">-->
                <!--                                            {{ AppStore.data.configuration[AppStore.locale].timeline_answer_title }}-->
                <!--                                        </div>-->
                <!--                                    </v-col>-->
                <!--                                    <v-col cols="12 d-flex">-->
                <!--                                        <div>-->
                <!--                                            {{ answer.content }}-->
                <!--                                        </div>-->
                <!--                                        <div class="ml-auto">-->
                <!--                                            <v-img-->
                <!--                                                    v-if="!_.isEmpty(AppStore.data.configuration[AppStore.locale].client_logo_2)"-->
                <!--                                                    max-width="50"-->
                <!--                                                    :src="AppStore.data.configuration[AppStore.locale].client_logo_2"-->
                <!--                                            ></v-img>-->
                <!--                                        </div>-->
                <!--                                    </v-col>-->
                <!--                                </v-row>-->

                <!--                            </v-card>-->
                <!--                        </v-col>-->
                <!--                    </v-row>-->

                <!--                </v-container>-->
            </v-row>
        </v-container>
    </div>
</template>

<script>
import AppStore from "../../../stores/AppStore";
import _ from "lodash";
import Helpers from "../../../services/Helpers";
import {EventBus} from "../../../services/Request";
import App from "../../../App.vue";

export default {
    name: 'timeline-view',

    components: {},

    props: {},

    data: () => ({
        AppStore,
        Helpers,
        EventBus,
        isLandscape: Helpers.isLandscape(),
        isPortrait: Helpers.isPortrait(),
    }),

    created() {
        window.addEventListener("orientationchange", () => {
            this.isLandscape = Helpers.isLandscape()
            this.isPortrait = Helpers.isPortrait()
        }, false);

        window.addEventListener("resize", () => {
            this.isLandscape = Helpers.isLandscape()
            this.isPortrait = Helpers.isPortrait()
        }, false);
    },

    mounted() {

    },

    computed: {
        App() {
            return App
        },
        _() {
            return _;
        },
    },

    watch: {},

    methods: {
        // getValueForStat(stat_key) {
        //     switch (stat_key.key) {
        //         case 'ratings_total':
        //             return 'avis au total'
        //         case 'rating_id_count':
        //             return 'avis hier'
        //         case 'ratings_90_days_rolling':
        //             return 'avis 3 derniers mois'
        //         case 'ratings_30_days_rolling':
        //             return 'avis 30 derniers jours'
        //         case 'ratings_12_months_rolling':
        //             return 'avis 12 derniers mois'
        //         case 'has_comment_sum':
        //             return 'commentaires hier'
        //         case 'has_comments_90_days_rolling':
        //             return 'commentaires 3 derniers mois'
        //         case 'has_comments_30_days_rolling':
        //             return 'commentaires 30 derniers jours'
        //         case 'has_comments_12_months_rolling':
        //             return 'commentaires 12 derniers mois'
        //         case 'has_answers_sum':
        //             return 'réponses hier'
        //         case 'has_answers_12_months_rolling':
        //             return 'réponses 3 derniers mois'
        //         case 'has_answers_90_days_rolling':
        //             return 'réponses 30 derniers jours'
        //         case 'has_answers_30_days_rolling':
        //             return 'réponses 12 derniers mois'
        //         case 'rate_avg':
        //             return 'note moyenne /5 hier'
        //         case 'rate_12_months_rolling':
        //             return 'note moyenne /5 3 derniers mois'
        //         case 'rate_90_days_rolling':
        //             return 'note moyenne /5 30 derniers jours'
        //         case 'rate_30_days_rolling':
        //             return 'note moyenne /5 12 derniers mois'
        //         case 'standard_rate_avg':
        //             return '% satisfaction hier'
        //         case 'standard_rate_12_months_rolling':
        //             return '% satisfaction 3 derniers mois'
        //         case 'standard_rate_90_days_rolling':
        //             return '% satisfaction 30 derniers jours'
        //         case 'standard_rate_30_days_rolling':
        //             return '% satisfaction 12 derniers mois'
        //         default:
        //             return stat_key
        //     }
        // },
    }
};
</script>
<style>
.stats {
    font-size: 3.5rem !important;
}
</style>
